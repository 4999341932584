import React, { useState } from "react";

import Header from "./components/Header";
import Footer from "./components/Footer";
import './App.css';

import frente from './assets/img/frente.png';


function App() {

  const [selectedItem, setSelectedItem] = useState(null);

  // Información para mostrar, puedes modificarla según lo que necesites
      const info = {
        item1: {
          title: "Economía y Administración",
          text: "En un mundo globalizado, donde la incertidumbre atraviesa la totalidad de la estructura de una organización, es necesario tener en cuenta el paradigma flexible en la gestión de los grupos sociales. Asimismo, es imposible desconocer que en la actualidad hay una cantidad creciente de propuestas alternativas a las formas hegemónicas de organización en el marco de la globalización.",
          link: "https://www.igualdadycalidadcba.gov.ar/SIPEC-CBA/publicaciones/EducacionSecundaria/LISTO%20PDF/ORIENTACION%20ECONOMIA%20Y%20ADMINISTRACION%2017-11-11.pdf"
        },
        item2: {
          title: "Arte: Multimedia",
          text: "La Multimedia supone la exploración y la participación en nuevos territorios artísticos en la intersección entre arte, ciencia y tecnología. Esto requiere el desarrollo de herramientas conceptuales y técnicas para la creación estética con nuevos medios, que incorporen una reflexión profunda sobre el lugar de la tecnología en los discursos artísticos.",
          link: "https://www.igualdadycalidadcba.gov.ar/SIPEC-CBA/publicaciones/EducacionSecundaria/LISTO%20PDF/ORIENTACION_ARTES_multimedia%2017-02-2012.pdf"
        }
      };

  // Función para cambiar el ítem seleccionado
  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <div>
      <Header onItemClick={handleItemClick} />
      <div className="container mt-3 ">
        <div className="row">
          <div className="col-12">
            <div class="card  mb-3">
              <img className="img-fluid p-4" src={frente} />
              <div class="card-body d-none">
                <h5 class="card-title">Card title</h5>
                <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
              </div>
            </div>
          </div>

        </div>
        <div className="card">
        <div class="card-header" id="especialidades">
          Especialidades
        </div>
          <div className="row d-flex justify-content-center mt-3 mb-4">

            <div className="col-12 col-md-5">
              <div className="list-group">

                <button
                  type="button"
                  className="list-group-item list-group-item-action"
                  onClick={() => handleItemClick("item1")}
                >
                  Economía y Administración
                </button>
                <button
                  type="button"
                  className="list-group-item list-group-item-action"
                  onClick={() => handleItemClick("item2")}
                >
                  Arte: Multimedia
                </button>
              </div>
            </div>

            <div className="col-12 col-md-6">
              {selectedItem && (
                <div className="card text-start">
                  <div className="card-body">
                    <h4 className="card-title">{info[selectedItem]?.title}</h4>
                    <p className="card-text">{info[selectedItem]?.text}</p>
                    <p>
                      Encuadre General del Diseño Curricular de la Educación Secundaria. 2011-2015
                    </p>
                    <a
                      href={info[selectedItem]?.link}
                      className="btn btn-outline-secondary"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ir
                    </a>
                  </div>
                </div>
              )}
            </div>

          </div>
    </div>
    <div className="card mt-3 p-3">
    <div className="row mt-3">
    <div className="col-10 d-flex justify-content-center">
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d830.4632040867548!2d-64.06283563987598!3d-31.020945331946567!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94326223033035bd%3A0x76d3261de36cea9f!2sColegio%20Presbiterio%20Jos%C3%A9%20Bonoris!5e0!3m2!1ses-419!2sar!4v1732539966377!5m2!1ses-419!2sar" 
              width="800" 
              height="300" 
        
              allowfullscreen="" 
              loading="lazy" 
              referrerpolicy="no-referrer-when-downgrade">
            </iframe>
        </div>

        <div className="col-12 col-md-2">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">           
                 <a href="https://www.instagram.com/colegiobonoris/" target="_blank">
                   <i class="fab fa-instagram"></i> Instagram
                 </a>
              </li>
              <li class="list-group-item">
                <a href="https://www.facebook.com/IPEM165/" target="_blank">
                   <i class="fab fa-instagram"></i> Facebook
                 </a>
              </li>
              <li class="list-group-item">
              <a href=" https://www.youtube.com/@presbiterojosebonoris6443" target="_blank">
                   <i class="fab fa-instagram"></i> Youtube
                 </a>
              </li>

            </ul>



           

        </div>

      </div>

      </div>
</div>

      <Footer />
    </div>
  )
}

export default App;

import React from 'react'
import logo_footer from '../assets/img/logo.png';

export default function Footer() {
  return (
    <nav className="mt-5 mb-1 navbar-light bg-light">
    <div className="container " >
        <div className=" text-muted " >
           <div className="mx-3 col-6 mb-3">
               <a href='' onClick={() => window.location.reload()} >
                <img 
                    className="banner" 
                    src={ logo_footer } 
                    alt="Ministerio banner"
                 
                />
               </a>
                <p className='mx-3 mt-3'>
                     ipem165@pjb.edu.ar
                    <br/>  
                    © 2024 Copyright IPEM 165 - Pbro. José Bonoris
                </p>           
            </div>

        </div>
    </div>  
    </nav>
  )
}

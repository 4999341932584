
import React from 'react'
import logo_bonoris from '../assets/img/logo.png';

export default function Header({ onItemClick }) {
  return (
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
          <a className="navbar-brand mx-3" href="" onClick={() => window.location.reload()} >
              <img src={logo_bonoris} className="brand-logo" />
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="" onClick={() => window.location.reload()} >Inicio</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">Docentes</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">Estudiantes</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">Familia</a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Nosotros
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown"> 
                <li><a class="dropdown-item disabled" href="#">Especialidades</a></li> 
                <li><hr class="dropdown-divider"/></li>                  
                  <li><a class="dropdown-item" href="#especialidades" onClick={() => onItemClick('item1')}>Economía y Administración</a></li>
                  <li><a class="dropdown-item" href="#especialidades" onClick={() => onItemClick('item2')}>Arte: Multimedia</a></li>
                </ul>
              </li>
            </ul>
            <form class="d-flex d-none">
              <input class="form-control me-2" type="search" placeholder="Buscar" aria-label="Search"/>
              <button class="btn btn-outline-secondary" type="submit">Buscar</button>
            </form>
          </div>
        </div>
      </nav>
   )
}
